import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import chunk from 'lodash/chunk'

const Presenters = () => (
  <StaticQuery
    query={graphql`
      query {
        allPresentersJson(limit: 4) {
          edges {
            node {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 1240) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
            }
          }
        }
      }
    `}
    render={data => {
      const presenters = data.allPresentersJson.edges.map(e => e.node)
      return (
        <>
          {chunk(presenters, 3).map((chunk, i) => (
            <div key={`chunk-${i}`} className="columns">
              {chunk.map((node, i) => (
                <div
                  key={`node-${i}`}
                  className="column is-one-third mt20-touch mb20-touch"
                >

                  <Img
                    className="presenters--image"
                    fluid={node.image.childImageSharp.fluid}
                  />
                  <h4 className="title is-4 mt12 mb8">{node.title}</h4>
                  <div>
                    <p>{node.description}</p>
                  </div>
                </div>
              ))
              }
            </div>
          ))}
        </>
      )
    }}
  />
)

export default Presenters
