import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import chunk from 'lodash/chunk'

const Blogs = () => (
  <StaticQuery
    query={graphql`
      query {
        allBlogsJson(limit: 5) {
          edges {
            node {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 1240) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              link
            }
          }
        }
      }
    `}
    render={data => {
      const blogs = data.allBlogsJson.edges.map(e => e.node)
      return (
        <>
          {chunk(blogs, 2).map((chunk, i) => (
            <div key={`chunk-${i}`} className="columns">
              {chunk.map((node, i) => (
                <div
                  key={`node-${i}`}
                  className="column is-half mt20-touch mb20-touch"
                >
                  <div className="columns">
                    <div className="column">
                      <Img
                        className="blogs--image"
                        fluid={node.image.childImageSharp.fluid}
                      />
                    </div>
                    <div className="column">
                      <div className="h50p">
                        <h4 className="title is-4 weak">{node.title}</h4>
                      </div>
                      <div className="h50p flex-center pt12">
                        <a
                          className="button is-black is-outlined is-fullwidth pb20 pt20 hover-sweep-to-right"
                          href={node.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          記事を読む
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </>
      )
    }}
  />
)

export default Blogs
