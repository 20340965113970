import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

class WorksCompanies extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWorksCompaniesJson(limit: 4) {
              edges {
                node {
                  title
                  link
                  image {
                    childImageSharp {
                      fluid(maxWidth: 1240) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  duration
                  roles
                }
              }
            }
          }
        `}
        render={data => {
          const worksCompanies = data.allWorksCompaniesJson.edges.map(
            e => e.node
          )
          return (
            <>
              <div className="columns">
                {worksCompanies.map((node, i) => (
                  <div
                    key={`node-${i}`}
                    className="column works--card"
                    data-aos="fade-up"
                    data-aos-delay={100 * i}
                  >
                    <a
                      className="has-text-black hover-shrink"
                      href={node.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className="works--logo-image"
                        placeholderStyle={{ visibility: 'hidden' }}
                        fluid={node.image.childImageSharp.fluid}
                      />
                      <small>{node.duration}</small>
                      <ul className="mt20">
                        {node.roles.map((role, i) => (
                          <li key={`role-${i}`}>{role}</li>
                        ))}
                      </ul>
                    </a>
                  </div>
                ))}
              </div>
            </>
          )
        }}
      />
    )
  }
}

export default WorksCompanies
