import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import chunk from 'lodash/chunk'

class WorksProducts extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWorksProductsJson(limit: 12) {
              edges {
                node {
                  title
                  link
                  image {
                    childImageSharp {
                      fluid(maxWidth: 1240) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  description
                }
              }
            }
          }
        `}
        render={data => {
          const worksProducts = data.allWorksProductsJson.edges.map(e => e.node)
          return (
            <>
              {chunk(worksProducts, 2).map((chunk, i) => (
                <div key={`chunk-${i}`} className="columns">
                  {chunk.map((node, i) => (
                    <div
                      key={`node-${i}`}
                      className="column"
                      data-aos="fade-up"
                    >
                      <a
                        className="w100p hover-shrink"
                        href={node.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img fluid={node.image.childImageSharp.fluid} />
                        <h4 className="title is-4 mt12 mb8">{node.title}</h4>
                        <div>
                          <p>{node.description}</p>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        }}
      />
    )
  }
}

export default WorksProducts
