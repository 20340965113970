import React from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Blogs from '../components/Blogs'
import Presenters from '../components/Presenters'
import WorksCompanies from '../components/works-companies'
import WorksProducts from '../components/works-products'

import arrow from '../images/arrow.svg'
import logo from '../images/logo.svg'
import aboutme from '../images/aboutme.png'
import createIcon from '../images/icons/create-icon.svg'
import leanIcon from '../images/icons/lean-icon.svg'
import discoverIcon from '../images/icons/discover-icon.svg'
import slashIcon from '../images/slash.svg'
import fbIcon from '../images/social/facebook.svg'
import twitterIcon from '../images/social/twitter.svg'
import instaIcon from '../images/social/instagram.svg'
import dribbbleIcon from '../images/social/dribbble.svg'
import wantedlyIcon from '../images/social/wantedly.svg'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sectionStyle: {
        backgroundColor: '#F7F5F2',
        transition: 'background-color 800ms ease-out',
      },
    }
  }

  componentDidMount() {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-out-sine',
      once: true,
    })
    const hash = window.location.hash
    if (hash !== '') {
      // ex) hash = #home
      const id = hash.slice(1)
      this.smoothScroll({ id: id, offset: 0 })
    }
  }

  smoothScroll(param) {
    const element = document.getElementById(param.id)
    if (!element) {
      return
    }
    window.scroll({
      top: element.offsetTop - param.offset,
      behavior: 'smooth',
    })
  }

  changeSectionStyle(color) {
    this.setState({
      sectionStyle: {
        backgroundColor: color,
        transition: 'background-color 800ms ease-out',
      },
    })
  }

  render() {
    return (
      <Layout>
        <div>
          <Waypoint
            onEnter={() => {
              console.log('onEnter top section')
              this.changeSectionStyle('#F7F5F2')
            }}
            topOffset="50%"
            onLeave={() => {
              console.log('onLeave top section')
              this.changeSectionStyle('#F4EEF0')
            }}
          >
            <section
              className="hero is-fullheight"
              id="home"
              style={this.state.sectionStyle}
            >
              <div className="hero-head fadein-header">
                <nav className="navbar is-fixed-top">
                  <div className="container is-fluid">
                    <div className="navbar-brand">
                      <div className="navbar-item">
                        <h1 className="title is-4">hina.design</h1>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="hero-body">
                <div className="container">
                  <object
                    className="logo"
                    type="image/svg+xml"
                    aria-label="logo"
                    data={logo}
                  />
                </div>
              </div>
              <div className="container-arrow">
                <figure className="image has-image-centered is-64x64 has-text-centered">
                  <object
                    type="image/svg+xml"
                    aria-label="discoverIcon"
                    data={arrow}
                  />
                </figure>
              </div>
            </section>
          </Waypoint>
          <Waypoint
            topOffset="50%"
            onEnter={({ previousPosition, currentPosition, event }) => {
              console.log('onEnter profile section')
              if (previousPosition == 'above') {
                this.changeSectionStyle('#F4EEF0')
              }
            }}
            onLeave={() => {
              console.log('onLeave profile section')
            }}
          >
            <section
              className="section"
              id="profile"
              style={this.state.sectionStyle}
            >
              <div className="container">
                <h2 className="title" data-aos="fade-left">
                  PROFILE
                </h2>
                <div className="columns">
                  <div className="column" data-aos="fade-left">
                    <div>
                      <div className="subtitle">酒匂 ひな子</div>
                      <p>サービスデザイナー/PdMのフリーランス　Basecamp所属</p>
                      <p>
                        2011年4月より株式会社エウレカにてインターンとして勤務を開始し、大手企業のWEBやアプリのデザインや設計・ディレクションを行い、2013年度に同社へ入社。
                      </p>
                      <p>
                        2012年10月からは恋活・婚活アプリPairsの立ち上げに携わり、2018年3月までプロダクトオーナー兼リードデザイナーとして、デザイン・設計、ディレクション、チームマネジメントに励み、会員数800万人のNo.1マッチングサービスに成長を牽引しました。
                      </p>
                      <p>
                        2018年3月よりフリーランスとして独立。
                        翌年2019年9月に自社サービスとしてLINEでできる生理予測・共有アプリ「ペアケア」をリリースし、10月に株式会社Entaleを創業しました。
                      </p>
                    </div>
                  </div>
                  <div
                    className="column"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <img src={aboutme} alt="aboutme" />
                  </div>
                </div>
              </div>
            </section>
          </Waypoint>
          <Waypoint
            onEnter={() => {
              console.log('onEnter whatido section')
              this.changeSectionStyle('#E8F4F4')
            }}
            onLeave={() => {
              console.log('onLeave whatido section')
            }}
          >
            <section
              className="section"
              id="whatido"
              style={this.state.sectionStyle}
            >
              <div className="container">
                <h2 className="title" data-aos="fade-left">
                  WHAT I DO
                </h2>
                <div className="subtitle" data-aos="fade-left">
                  事業の立ち上げからGrowthまで、Designの力で成功に導きます
                </div>
                <div className="columns">
                  <div className="column">
                    <div
                      className="has-text-centered mt40 p40"
                      data-aos="fade-up"
                    >
                      <div>
                        <figure className="image has-image-centered is-64x64">
                          <object
                            type="image/svg+xml"
                            aria-label="discoverIcon"
                            data={discoverIcon}
                          />
                        </figure>
                        <h4 className="title is-4 mb0">DISCOVER</h4>
                        <small>顧客の潜在ニーズの発見を主導</small>
                      </div>
                      <ul className="mt20">
                        <li>事業戦略構築</li>
                        <li>ユーザインタビュー設計</li>
                        <li>ジャーニーマップ作成</li>
                      </ul>
                    </div>
                  </div>
                  <div className="column">
                    <div
                      className="has-text-centered mt40 p40"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div>
                        <figure className="image has-image-centered is-64x64">
                          <object
                            type="image/svg+xml"
                            aria-label="createIcon"
                            data={createIcon}
                          />
                        </figure>
                        <h4 className="title is-4 mb0">CREATE</h4>
                        <small>素早く・高品質でユーザーに届ける</small>
                      </div>
                      <ul className="mt20">
                        <li>UX設計</li>
                        <li>UIデザイン</li>
                        <li>グラフィックデザイン</li>
                      </ul>
                    </div>
                  </div>
                  <div className="column">
                    <div
                      className="has-text-centered mt40 p40"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div>
                        <figure className="image has-image-centered is-64x64">
                          <object
                            type="image/svg+xml"
                            aria-label="leanIcon"
                            data={leanIcon}
                          />
                        </figure>
                        <h4 className="title is-4 mb0">LEAN</h4>
                        <small>検証を通してビジネスを成功へ導く</small>
                      </div>
                      <ul className="mt20">
                        <li>プロトタイプ作成</li>
                        <li>ユーザーテスト</li>
                        <li>仮説検証</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Waypoint>
          <Waypoint
            onEnter={() => {
              console.log('onEnter work section')
              this.changeSectionStyle('#F7F5F2')
            }}
            onLeave={() => {
              console.log('onLeave work section')
            }}
          >
            <section
              className="section"
              id="work"
              style={this.state.sectionStyle}
            >
              <div className="container">
                <h2 className="title" data-aos="fade-left">
                  WORK
                </h2>
                <div>
                  <WorksProducts />
                </div>
              </div>
            </section>
          </Waypoint>
          <Waypoint
            onEnter={() => {
              console.log('onEnter presenter section')
              this.changeSectionStyle('#F4EEF0')
            }}
            onLeave={() => {
              console.log('onLeave presenter section')
            }}
          >
            <section
              className="section"
              id="presenter"
              style={this.state.sectionStyle}
            >
              <div className="container">
                <h2 className="title" data-aos="fade-left">
                  PRESENTER
                </h2>
                <div data-aos="fade-up">
                  <Presenters />
                </div>
              </div>
            </section>
          </Waypoint>
          <Waypoint
            onEnter={() => {
              console.log('onEnter blog section')
              this.changeSectionStyle('#E8F4F4')
            }}
            onLeave={() => {
              console.log('onLeave blog section')
            }}
          >
            <section
              className="section"
              id="blog"
              style={this.state.sectionStyle}
            >
              <div className="container">
                <h2 className="title" data-aos="fade-left">
                  BLOG
                </h2>
                <div data-aos="fade-up">
                  <Blogs />
                </div>
              </div>
            </section>
          </Waypoint>
          <Waypoint
            onEnter={() => {
              console.log('onEnter contact section')
              this.changeSectionStyle('#A9D1D5')
            }}
            onLeave={() => {
              console.log('onLeave contact section')
            }}
          >
            <section
              className="section"
              id="contact"
              style={this.state.sectionStyle}
            >
              <div className="container" data-aos="fade-left">
                <h2 className="title">CONTACT</h2>
                <div>
                  <div className="subtitle">
                    <a
                      className="has-text-black"
                      href="mailto:hinako.sakawa@entale.jp"
                    >
                      hinako.sakawa@entale.jp
                    </a>
                  </div>
                  <p className="contact-p">
                    お仕事のご相談、取材や掲載などのご依頼、その他ご意見・ご質問などお気軽にご連絡ください。
                  </p>
                </div>
                <div className="columns is-mobile">
                  <div className="column is-2-mobile is-1-tablet">
                    <a
                      className="has-text-black"
                      href="https://www.facebook.com/sakawa.hinako"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="image has-image-centered is-32x32">
                        <img
                          className="hover-shrink"
                          src={fbIcon}
                          alt="fbIcon"
                        />
                      </figure>
                    </a>
                  </div>
                  <div className="column is-2-mobile is-1-tablet">
                    <a
                      className="has-text-black"
                      href="https://twitter.com/HinakoSakawa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="image has-image-centered is-32x32">
                        <img
                          className="hover-shrink"
                          src={twitterIcon}
                          alt="twitter"
                        />
                      </figure>
                    </a>
                  </div>
                  <div className="column is-2-mobile is-1-tablet">
                    <a
                      className="has-text-black"
                      href="https://www.instagram.com/ohina/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="image has-image-centered is-32x32">
                        <img
                          className="hover-shrink"
                          src={instaIcon}
                          alt="instaIcon"
                        />
                      </figure>
                    </a>
                  </div>
                  <div className="column is-2-mobile is-1-tablet">
                    <a
                      className="has-text-black"
                      href="https://dribbble.com/hinako_s"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="image has-image-centered is-32x32">
                        <img
                          className="hover-shrink"
                          src={dribbbleIcon}
                          alt="dribbbleIcon"
                        />
                      </figure>
                    </a>
                  </div>
                  <div className="column is-2-mobile is-1-tablet">
                    <a
                      className="has-text-black"
                      href="https://www.wantedly.com/users/112407"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="image has-image-centered is-32x32">
                        <img
                          className="hover-shrink"
                          src={wantedlyIcon}
                          alt="wantedlyIcon"
                        />
                      </figure>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </Waypoint>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
